import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import {
  Check as IconCheck,
  Close as IconCross,
  ContentCopy as IconCopy,
} from '@mui/icons-material'

import DisputeActions, {
  iDispute,
  iStatistic,
  STATUS_CONFIRMED,
  STATUS_LIST,
  STATUS_REJECTED
} from '../../redux/disputeRedux'
import VotingActions from '../../redux/votingRedux'
import Candidate, { iEstate } from '../../components/estate/Candidate'


interface iProps {
  fetchingD: boolean
  disputes: iDispute[]

  fetchingV: boolean
  vote: any
  target: iEstate
  candidates: iEstate[]

  statistic: iStatistic

  clearCurrent: () => void,
  selectDisputes: () => void
  updateDispute: (id: number, data: any) => void
  loadVoteById: (id: number) => void
}

function ManageDisputePage(props: iProps) {
  const { fetchingD, fetchingV, disputes, vote, target, candidates, statistic } = props
  const [showAll, setShowAll] = React.useState<boolean>(false)
  let lastRenderedCandidateId: number = 0

  useEffect(() => {
    props.selectDisputes()
  }, [])

  useEffect(() => {
    const obj = document.getElementById('candidates-without-disputes')
    if (showAll && obj) {
      obj.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      })
    }
  }, [showAll])

  useEffect(() => {
    if (disputes.length) {
      const id = disputes[0].vote
      if (id) props.loadVoteById(id)
    } else {
      props.selectDisputes()
    }
  }, [disputes])

  const getCandidateBgColor = (candidate: iEstate, dispute: iDispute|null = null) => {
    if (dispute?.is_matched_by_system === true && dispute?.is_matched_by_user !== true) {
      return '#00800026'
    }
    if (dispute?.is_matched_by_system === false && dispute?.is_matched_by_user === true) {
      return '#D32F2F26'
    }
  }

  const handleFinishDispute = async (candidateId: number, status: number) => {
    disputes.filter(dispute => dispute.candidate_id === candidateId).forEach(dispute => {
      const element = document.getElementById(`dispute-${dispute.id}`)
      if (element && dispute.id) {
        element.classList.add('fade-out')

        props.updateDispute(dispute.id, { status })
        setTimeout(() => {
          element.remove()
          if (document.getElementsByClassName('dispute-item').length === 0) {
            props.clearCurrent()
          }
        }, 1000)
      }
    })
  }

  if (!fetchingD && !fetchingV && !disputes.length) {
    return <Stack sx={{width: '100%'}} spacing={2}>
      <Alert severity="error">
        <AlertTitle>There are no any disputes for check</AlertTitle>
        Please try again later...
      </Alert>
    </Stack>
  }

  const renderControls = (candidate: iEstate, dispute: iDispute|null = null) => {
    const renderIsMatchedIcon = (flag: boolean) => {
      if (flag) return <IconCheck fontSize="small" titleAccess="Matched" sx={{display: 'flex'}} />
      return <IconCross fontSize="small" titleAccess="Not matched" sx={{display: 'flex'}} />
    }

    return (
      <ButtonGroup variant="outlined" size="small">
        {vote?.is_reference_vote && <Button color="error">Reference</Button>}
        {dispute && (
          <Button color="secondary">
            Matcher: #{dispute.user?.id} {dispute.user?.first_name} {dispute.user?.last_name}
            {renderIsMatchedIcon(dispute?.is_matched_by_user || false)}
          </Button>
        )}
        <Button color="secondary">
            System: {renderIsMatchedIcon(dispute?.is_matched_by_system || false)}
        </Button>
        {dispute && (<>
          <Button
            onClick={() => handleFinishDispute(
              dispute.candidate_id || 0,
              dispute.is_matched_by_system ? STATUS_REJECTED : STATUS_CONFIRMED,
            )}
            color="success"
          >
            Match
          </Button>
          <Button
            onClick={() => handleFinishDispute(
              dispute.candidate_id || 0,
              dispute.is_matched_by_system ? STATUS_CONFIRMED : STATUS_REJECTED,
            )}
            color="error"
          >
            Not match
          </Button>
          <Button
            color="info"
            title="Copy link"
            onClick={() => navigator.clipboard.writeText(`${window.location.origin}/disputes/${dispute.vote}/`)}
          >
            <IconCopy />
          </Button>
        </>)}
      </ButtonGroup>
    )
  }

  const renderStatistic = () => {
    return (
      <ButtonGroup variant="outlined" size="small" sx={{m: 1}}>
        <Button onClick={() => setShowAll(!showAll)}>
          Show: {showAll ? 'All' : 'Disputes'}
        </Button>
        {statistic?.count && statistic.count.map(statByStatus => {
          // @ts-ignore
          const label = STATUS_LIST[statByStatus.status]
          return <Button color="secondary">
            {label}: {statByStatus.total}
          </Button>
        })}
      </ButtonGroup>
    )
  }

  return (
    <Box width="100%">
      <Box sx={{
        backgroundColor: '#fff',
        marginBottom: '20px',
        position: 'sticky',
        zIndex: 5,
        boxShadow: 10,
        top: 64,
      }}>
        <Candidate renderBefore={renderStatistic()} estate={target} />
      </Box>
      {disputes.map((dispute) => {
        let candidate: iEstate|undefined = undefined
        candidates?.length && candidates.map(item => {
          if (item.id === dispute.candidate_id) candidate = item
          return null
        })

        const isSameCandidateAsPrev = lastRenderedCandidateId === dispute.candidate_id
        lastRenderedCandidateId = dispute.candidate_id
        return <Box
          id={`dispute-${dispute.id}`}
          key={`dispute-${dispute.id}`}
          className={'dispute-item'}
          sx={{
            marginBottom: isSameCandidateAsPrev ? '20px' : '',
            backgroundColor: candidate && getCandidateBgColor(candidate, dispute),
          }}
        >
          <Candidate
            estate={candidate}
            renderControls={candidate && renderControls(candidate, dispute)}
            hideImages={isSameCandidateAsPrev}
            renderAfter={
              <Stack
                direction="row"
                spacing={2}
                sx={{ p: 1 }}
              >
                <TextField
                  name={`${dispute.id}-comment`}
                  label="Comment"
                  multiline
                  rows={1}
                  value={dispute?.comment}
                  disabled={true}
                  fullWidth
                />
              </Stack>
            }
          />
        </Box>
      })}

      <div id="candidates-without-disputes">
        {(showAll && candidates?.length) && (<>
          <Typography component="h2" variant="h6" color="primary" sx={{m: 1}}>
            Else candidates:
          </Typography>
          {candidates.map(candidate => {
            if (disputes.find(dispute => dispute.candidate_id === candidate.id)) return null

            return <Candidate
              estate={candidate}
              renderControls={renderControls(candidate)}
            />
          })}
        </>)}
      </div>
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    disputes: state.dispute.list,
    fetchingD: state.dispute.fetching,
    statistic: state.dispute.statistic,

    fetchingV: state.voting.fetching,
    vote: state.voting.vote,
    target: state.voting.target,
    candidates: state.voting.candidates,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    clearCurrent: () => dispatch(DisputeActions.resetState()),
    selectDisputes: () => dispatch(DisputeActions.select()),
    updateDispute: (id: number, data: any) => dispatch(DisputeActions.updateDispute(id, data)),
    loadVoteById: (id: number) => dispatch(VotingActions.loadById(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageDisputePage)
